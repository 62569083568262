<template>
  <div>
    <h3>{{pageTitle}}</h3>
    <hr>
    <div class="tranBody">
      <table>
        <tr><td>Date:</td><td>{{tran.TRAN_DATE}}</td></tr>
        <tr><td>Account:</td><td>{{tran.ACCOUNT_NAME}}</td></tr>
        <tr><td>Account Owner:</td><td>{{tran.OWNER_NAME}}</td></tr>
        <tr><td>Amount:</td><td>{{tran.AMOUNT}}</td></tr>
        <tr><td>Details:</td><td>{{tran.DETAILS}}</td></tr>
        <tr><td>Related Account:</td><td>{{tran.ACCOUNT_NAME_RELATED}}</td></tr>
        <tr><td>Related Account Owner:</td><td>{{tran.OWNER_NAME_RELATED}}</td></tr>
        <tr><td>Created Timestamp:</td><td>{{tran.CREATED_DT_UTC}} UTC</td></tr>
        <tr><td>Created By:</td><td>{{tran.CREATED_BY}}</td></tr>
      </table>
    </div>

<!--    <div class="shareDiv clickable" @click="gotoShare" v-if="isAdmin && tran.IS_BUDGET === '0'">
      <div class="shareIconDiv"><font-awesome-icon :icon="['fas', 'share']" class="share"/></div>
      <div>Manage Sharing</div>
    </div>-->
    <div v-if="isAdmin">
      <div class="tranActionIconsDiv" >
        <div class="btn btn-danger delTranBtn tranBtn" @click="$emit('showDelModal',tran.ID)" v-if="pageMode==='last30days'"><font-awesome-icon :icon="['fas', 'trash']"/></div>
        <div class="btn btn-warning editTranBtn tranBtn" @click="$emit('editTran',tran.ID)" v-if="pageMode==='last30days'"><font-awesome-icon :icon="['fas', 'pencil-alt']"/></div>
        <div class="btn btn-warning editScheduleBtn tranBtn" @click="$emit('editTran',tran.ID)" v-if="pageMode==='upcoming'">Edit Schedule<br><span>(and all its upcoming transactions)</span></div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'TranDetails',
  props: {
    tran:{},
    isAdmin:Boolean,
    pageMode:String
  },
  data(){
    return{

    }
  },
  computed:{
    pageTitle(){
      return this.pageMode === 'upcoming' ? 'Schedule #' + this.tran.ID : 'Transaction #' +  this.tran.ID;
    }
  },
  methods:{


    gotoShare(){
      this.$root.playClickSound();
      this.$root.gotoRoute({ path: '/share', query: { tranId: this.tran.ID } })
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .tranBody{
    font-size:12px;
  }

  tr{
    height:20px;
  }

  td{
    padding:5px;
  }

  .shareDiv{
    margin:25px 5px;
    background-color: #b7fa8b;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    border-radius: 3px;
    width:unset;
  }


  .stopShareDiv{
    background-color: #f5bfa3;
  }

  .stopShareDiv .shareIconDiv{
    color:#57b846;
  }



  .shareIconDiv{
    padding:0 20px 0 10px;
    font-size:20px;
    color:orange;
  }

  .tranActionIconsDiv{
    padding:10px;
    display:flex;
    justify-content: center;
  }

  .tranBtn{
    font-size:15px;
    padding:2px 10px;
    margin:0 10px;
  }

  .editScheduleBtn{
    margin:10px;
  }

  .editScheduleBtn span{
    font-size:10px;
  }

</style>

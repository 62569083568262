<template>
  <div id="topNavDiv">
    <div class="topNav" >
      <div class="topNavTab clickable " :class="{active: pageMode === 'last30days'}" @click="setPageMode('last30days')">Last 30 Days</div>
      <div class="topNavTab clickable " :class="{active: pageMode === 'next30days'}" @click="setPageMode('next30days')">Next 30 Days</div>
      <div class="topNavTab clickable" :class="{active: pageMode === 'community'}" @click="setPageMode('community')">Community</div>
      <div class="topNavTab clickable" :class="{active: pageMode === 'article'}" @click="setPageMode('article')">Articles</div>
    </div>
  </div>

  <div class="mainContainer container" style="">



<!--    <div class="topNav">
      <carousel :items-to-show="4" :wrap-around="true" :transition="300"  v-model="currentTab" >
        <slide v-for="(tab,idx) in this.tabs" :key="idx" class="topNavTab" :class="{active: currentTab===idx}">
          <div class="carousel__item">{{tab.name}}</div>
        </slide>

      </carousel>
    </div>-->

    <div>
      <div id="family" v-if="pageMode === 'last30days' || pageMode === 'next30days'">
        <div id="transDiv">
          <div class="tran" v-for="tran in trans" :key="tran.ID" @click="openTranDetails(tran.ID)">
            <div class="tranDate">{{tran.TRAN_DATE}}</div>
            <div class="tranBottom">

              <div class="leftDiv">
                <div class="avatar"><img :src="getUserAvatar(tran.USER_ID)" alt="avatar"></div>
                <div class="desc">{{tran.DETAILS}}</div>
              </div>
              <div class="amount" :class="numberColor(tran.AMOUNT)">{{this.vueNumberFormat(tran.AMOUNT)}}</div>

            </div>

          </div>

          <div class="gotoBtnDiv clickable" @click="goToUserPage(trans[0].USER_ID)"><div class="btn gotoBtn">View more transactions<br>on each member's page</div></div>
        </div>
      </div>
    </div>



  </div>


  <div class="modalParent">
    <vue-final-modal v-model="showTranDetailsModal" classes="modal-container" content-class="modal-content">

      <div class="modal-body">
        <div>
          <tran-details :tran="activeTran"></tran-details>
        </div>
        <div style="display:flex;justify-content: center;">
          <div class="btn closeModal" @click="closeTranDetailsModal"><font-awesome-icon :icon="['fas', 'times']"/> Close</div>
        </div>
      </div>
    </vue-final-modal>
  </div>



</template>

<script>

import {VueFinalModal } from 'vue-final-modal';
import TranDetails from '@/components/TranDetails';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

var d = new Date();

export default {
  name: 'Trans',
  components: {
    VueFinalModal,
    TranDetails,
    //Carousel,
    //Slide,
  },
  data(){
    return{
      pageMode:'last30days',
      trans:[],
      familyUsers:[],
      activeTranId:null,
      showTranDetailsModal:false,

      tabs:[
        {'tab':'last30days', 'name':'Last 30 Days'},
        {'tab':'next30days', 'name':'Next 30 Days'},
        {'tab':'community', 'name':'Community'},
        {'tab':'articles', 'name':'Articles'}
      ],
    }
  },

  computed:{
    activeTran(){
      return this.activeTranId ? this.trans.filter(t=>t.ID === this.activeTranId)[0] : {};
    }
  },


  methods:{

    getKidsTrans: function(){
      let loader = this.$loading.show();
      this.axios
          .post(this.$hostApi,{task:'getKidsTrans',type:this.pageMode,today:d.toLocaleDateString('en-CA')},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.trans = response.data;
            loader.hide();
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    setPageMode(mode){
      this.$root.playClickSound();
      this.pageMode = mode;
      if(this.pageMode === 'last30days' || this.pageMode === 'next30days' ){
        this.getKidsTrans();
      }
    },

    getUser(userId){
      return this.familyUsers.filter(user => user.ID === userId)[0];
    },

    getUserAvatar(userId){
      var user = this.familyUsers.filter(user => user.ID === userId)[0];
      //console.log(user.AVATAR_URL);
      return user.AVATAR_URL;
    },

    numberColor:function(num){
      return num < 0 ? 'negative' : 'positive';
    },

    openTranDetails(tranId){
      this.activeTranId = tranId;
      this.showTranDetailsModal=true;
    },
    closeTranDetailsModal(){
      this.activeTranId = null;
      this.showTranDetailsModal=false
    },
    goToUserPage(userId){
      this.$root.gotoRoute({ path: '/member', query: { userId: userId } })
    },


  },
  created() {
    this.familyUsers = JSON.parse(localStorage.getItem('familyUsers'));
  },
  mounted() {
    this.getKidsTrans();
  },

}
</script>

<style scoped>
  #topNavDiv{
    width:100%;
    height:120px;
    background-color: #ffffff;
    display: flex;
    align-items: flex-end;
    justify-content:left;
    position:fixed;
    top:0;
  }


  .topNav{
    display: flex;
    justify-content: left;
    overflow: scroll;
    border-bottom: 0.5px solid #ffd603;

  }

  .topNav::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .topNav .topNavTab{
    border-radius:5px 5px 0 0 ;
    padding:10px;
    margin:0 2px;
    white-space: nowrap;
    background-color: #ffffff;
  }

  .topNav .topNavTab.active{
    background-color: #ffd603;
  }

  .mainContainer{
    margin-top:150px;
  }


  #transDiv{
    margin-top:30px;
  }

  .tran{
    height:110px;
    box-shadow: 1px 0 2px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 19%);
    margin:15px 0;
    border-radius: 3px;
    background-color: #f6f6c6;
  }

  .tranDate{
    text-align: center;
    font-size:12px;
    background-color: #a4a415;
    color:#ffffff;
    padding:5px;
  }

  .tranBottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;
  }

  .leftDiv{
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .avatar{
    margin-left:5px;
  }

  .avatar img{
    width:60px;
    height:60px;
    border:2px solid #9c9c9c;
    border-radius: 50%;
    padding:3px;
  }

  .desc{
    margin-left:20px;
  }

  .amount{
    font-size:18px;
  }







  /*modal*/
  .modalParent :deep(.modal-container) {
    width:100%;
    top:20px;

  }
  .modalParent :deep(.modal-content){
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1rem !important;
    padding: 1rem !important;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #f6f6c6;
    width:unset;

  }
  .modal__title {
    margin: 0 2rem 0 0;
    font-size: 14px;
    font-weight: 700;
  }
  .modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border: 0 solid #e2e8f0;
  }

  .modal-header{
    display: none;
  }


  .btn.closeModal {
    background-color: #e3e1e1;
    border: 0;
    padding:5px 10px;
    margin-top:20px;
  }

  .gotoBtnDiv{
    display:flex;
    justify-content: center;
    margin:30px 0 0 0;
  }

  .gotoBtn{
    background-color: #b7fa8b;
  }



</style>